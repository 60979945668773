<template>
    <div>
        <header class="header">
            <div class="container">
                <div class="header-row">
                    <div class="header-menu-dropdown">
                        <span>{{ $store.state.user.name }}</span>
                        <div class="header-avatar">
                            <img src="@/assets/user.png" alt="">
                        </div>
                        <ul class="header-menu-items">
                            <li>
                                <button type="button" @click="Logout">Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <section class="py-5">
            <BlockUI :message="msg" v-if="loading">
                <img :src="url" alt="Loading" width="60">
            </BlockUI>
            <div class="form-container">
                <div class="form-container-header">
                    <img width="150"
                        src="https://master-3.corporate.ferring.tech/wp-content/uploads/sites/16/2021/01/Ferring-logo.png"
                        alt="">
                    <h1 class="text-dark"><strong>Exhibit Fund Request Form</strong></h1>
                </div>
                <div class="form-notes mb-5">
                    <!-- Note 1 -->
                    <div class="form-notes-content pb-2">
                        <ul class="form-notes-list">
                            <li>W-9 form must be completed & signed before payment can be made</li>
                            <li>Supporting documentation for each program must be attached for approval</li>
                            <li>This form is not to be used for any type of meal, educational grant request or physician
                                assisted training</li>
                        </ul>
                    </div>
                    <div class="form-notes-content pt-2">
                        <div class="form-notes-highlight">
                            <p>Reminder: If you receive a request for a Charitable Donation or Coporate Sponsorship
                                (i.e. bronze, silver, gold level packages), please direct the requesting organization to
                                the Ferring Contributions webpage <a
                                    href="https://ferring.envisionpharma.com/vt_ferring/" target="_blank">https://ferring.envisionpharma.com/vt_ferring/</a>.
                                <strong>DO NOT</strong> submit these requests using the below exhibit funds request
                                portal.
                            </p>
                        </div>

                        <p>For questions or issues submitting an exhibit funds request, please contact your Marketing
                            Specialist.</p>

                        <!-- Note 2 -->
                        <div class="form-notes-highlight">
                            <p>NOTE: Funds are not guaranteed for programs without supporting documentation, and all
                                required approvals.</p>
                            <p>Please upload a W-9 (if it is not a credit card payment) and supporting documentation
                                before submitting.</p>
                        </div>
                    </div>
                </div>

                <div class="form-notes mb-5">
                    <div class="form-notes-content">
                        <p><strong>Please note the following signing authorities</strong></p>
                        <div class="form-grid">
                            <div class="grid-col">
                                <p> District Manager </p>
                                <p> $0 - $1500 </p>
                            </div>
                            <div class="grid-col">
                                <p> Regional Manager </p>
                                <p> $1501 - $3000 </p>
                            </div>
                            <div class="grid-col">
                                <p> National Sales Director </p>
                                <p> $3001 - $5000 </p>
                            </div>
                            <div class="grid-col">
                                <p> Business Unit Head </p>
                                <p> $5000+ </p>
                            </div>
                        </div>
                    </div>
                </div>

                <form id="main-form" name="main-form" enctype="multipart/form-data" @submit.prevent="submitForm">
                    <!-- Business Unit -->
                    <div class="group" id="business-unit-group">
                        <div class="col-left">
                            <label for="business-unit" class="label-required">Business Unit</label>
                        </div>
                        <div class="col-right">
                          
                                <select name="business-unit" id="business-unit" v-model="form.businessUnitId" required
                                    @change="getDistrict">
                                    <option value="">Please select a value...</option>
                                    <option :value="buisnessUnit.BusinessUnitID" :key="buisnessUnit.BusinessUnitID"
                                        v-for="(buisnessUnit) in businessUnits">{{
                                            buisnessUnit.groupBusinessUnitName
                                        }}</option>
                                </select>
                              
                          
                        </div>
                    </div>
                    <!-- District -->
                    <div class="group" id="district-group">
                        <div class="col-left">
                            <label for="district" class="label-required">District</label>
                        </div>
                        <div class="col-right">
                            <select name="district" id="district" v-model="form.DistrictId" required
                                @change="ModifiedDistrictId">
                                <option value="">Please select a value...</option>
                                <option :value="district.DistrictID" :key="district.DistrictID"
                                    v-for="(district) in districts">{{
                                        district.District_Name
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- Brand -->
                    <div class="group" id="brand-group">
                        <div class="col-left">
                            <label for="brand" class="label-required">Brand</label>
                        </div>
                        <div class="col-right">
                            <select name="brand" id="brand" v-model="form.BrandNameId" required>
                                <option value="">Please select a value...</option>
                                <option :value="brand.Brand_id" :key="brand.Brand_id"
                                    v-for="(brand) in brands">{{
                                        brand.BrandName
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- Territory Number -->
                    <div class="group" id="territory-number-group">
                        <div class="col-left">
                            <label for="territory-number" class="label-required">Territory</label>
                        </div>
                        <div class="col-right">
                            <select name="district" id="district" v-model="form.TerritoryNumber" required>
                                <option value="">Please select a value...</option>
                                <option :value="territory.TerritoryID" :key="territory.TerritoryID"
                                    v-for="(territory) in territories">{{
                                        territory.Territory_Name
                                    }}</option>
                            </select>
                            
                        </div>
                    </div>
                    <!-- Date of program -->
                    <div class="group" id="date-of-program-group">
                        <div class="col-left">
                            <label for="date-of-program" class="label-required">Date of Exhibit</label>
                        </div>
                        <div class="col-right">
                            <date-picker v-model="form.DateOfProgram" format="MM-DD-YYYY" id="date-of-program"
                                :editable=true name="date-of-program" :clearable=false valueType='YYYY-MM-DD'
                                 :input-attr="{ required: true }"
                                :pick="DateOfProgramChanged()"></date-picker>
                            <!-- <input type="date" id="date-of-program" name="date-of-program" v-model="form.DateOfProgram"
                            placeholder="mm-dd-yyyy"
                                required> -->
                        </div>
                    </div>
                    <!-- Requesting Organization -->
                    <div class="group" id="requesting-organization-group">
                        <div class="col-left">
                            <label for="requesting-organization" class="label-required">Requesting Organization</label>
                        </div>
                        <div class="col-right">
                            <input type="text" id="requesting-organization" v-model="form.RequestingOrganization"
                                name="requesting-organization" required>
                        </div>
                    </div>
                    <!-- Title of Program -->
                    <div class="group" id="title-of-program-group">
                        <div class="col-left">
                            <label for="title-of-program" class="label-required">Title of Exhibit</label>
                        </div>
                        <div class="col-right">
                            <input type="text" id="title-of-program" name="title-of-program" v-model="form.titleOfProgram"
                                required>
                        </div>
                    </div>
                    <!-- Program Location -->
                    <div class="group" id="program-location-group">
                        <div class="col-left">
                            <label for="program-location" class="label-required">Exhibit Location</label>
                        </div>
                        <div class="col-right">
                            <input type="text" id="program-location" name="program-location" v-model="form.programLocation"
                                required>
                        </div>
                    </div>
                    <!-- Speaker/Center Name -->
                    <div class="group" id="speakercenter-name-group">
                        <div class="col-left">
                            <label for="speakercenter-name" class="label-required">Speaker/Center Name</label>
                        </div>
                        <div class="col-right">
                            <input type="text" id="speakercenter-name" v-model="form.speakerCenterName"
                                name="speakercenter-name" required>
                        </div>
                    </div>
                    <!-- Speaker Center Address -->
                    <div class="group" id="speaker-center-address-group">
                        <div class="col-left">
                            <label for="speaker-center-address" class="label-required">Speaker Center
                                Address</label>
                        </div>
                        <div class="col-right">
                            <textarea name="speaker-center-address" v-model="form.speakerCenterAddress"
                                id="speaker-center-address" required></textarea>
                        </div>
                    </div>
                    <!-- Address to send check -->
                    <div class="group" id="address-to-send-check-group">
                        <div class="col-left">
                            <label for="address-to-send-check">Address to send check</label>
                        </div>
                        <div class="col-right">
                            <textarea name="address-to-send-check" id="address-to-send-check"
                                v-model="form.addressToSendCheck"></textarea>
                        </div>
                    </div>
                    <!-- Dollar amount requested -->
                    <div class="group" id="dollar-amount-requested-group">
                        <div class="col-left">
                            <label for="dollar-amount-requested" class="label-required">Dollar amount
                                requested</label>
                        </div>
                        <div class="col-right">
                            <input type="number" id="dollar-amount-requested" v-model="form.dollarAmountRequested" min="0"
                                @input="filterNonPositive" name="dollar-amount-requested" required>
                        </div>
                    </div>
                    <!-- Type of cost -->
                    <div class="group" id="type-of-cost-group">
                        <div class="col-left">
                            <label for="type-of-cost" class="label-required">Type of cost</label>
                        </div>
                        <div class="col-right">
                            <div class="checkbox">
                                <input type="checkbox" id="type-of-cost" name="type-of-cost"
                                     v-model="form.TypeOfCost" required>
                                <label for="type-of-cost">Exhibit/Display Fee (GL Code 773007)</label>
                            </div>
                        </div>
                    </div>
                    <!-- Is Credit Card payment accepted? -->
                    <div class="group" id="credit-card-accepted-group">
                        <div class="col-left">
                            <label for="credit-card-accepted" class="label-required">Is Credit Card payment
                                accepted?</label>
                        </div>
                        <div class="col-right">

                            <div class="radio">
                                <input type="radio" id="credit-card-accepted-yes" name="credit-card-accepted"
                                    v-model="form.CreditCardAccepted" value="yes" required><label
                                    for="credit-card-accepted-yes">Yes</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="credit-card-accepted-no" v-model="form.CreditCardAccepted"
                                    name="credit-card-accepted" value="no" required><label
                                    for="credit-card-accepted-no">No</label>
                            </div>
                        </div>
                    </div>
                    <!-- W-9 attached -->
                    <div class="group" id="w-9-attached-group">
                        <div class="col-left">
                            <label for="w-9-attached" class="label-required">W-9 attached</label>
                        </div>
                        <div class="col-right">
                            <div class="radio">
                                <input type="radio" id="w-9-attached-yes" name="w-9-attached" value="yes"
                                    v-model="form.W9Attached" required :disabled="w9AttachedDisableYes">
                                <label for="w-9-attached-yes">Yes</label>
                            </div>
                            <div class="radio">
                                <input 
                                type="radio" id="w-9-attached-no" v-model="form.W9Attached" name="w-9-attached"
                                    value="no" required :disabled="w9AttachedDisableNo">
                                <label for="w-9-attached-no">No – Credit Card Payment Accepted</label>
                            </div>
                        </div>
                    </div>

                    <!-- Attachments -->
                    <div class="group" id="attachments-group">
                        <div class="col-left">
                            <label for="attachments" class="label-required">Attachments</label>
                        </div>
                        <div class="col-right">
                            <div class="file">
                                <input type="file" id="attachments" name="attachments" @change="uploadFile" multiple
                                ref="file" accept=".xlsx,.pdf,.png,.jpeg,.jpg" :required="this.form.Attachment.length == 0">
                                <label for="attachments">choose a file</label>
                            </div>

                            <div class="file-name">
                                <ul>

                                    <li v-for="(attachment, i) in form.Attachment" :key="i">{{ attachment.name }}
                                        <span @click="removeFile(i)">
                                            <i class="fa fa-times" style="color:red"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <p> <small><i class="fa fa-info-circle"></i> Allowed File Type is <strong>.xlsx, .pdf, .png,
                                        .jpeg, .jpg </strong> and size should not be more than
                                    <strong>250mb</strong></small> </p>

                            <p> <small><i class="fa fa-info-circle"></i> It is mandatory to attach an advertisement
                                    placement request letter or the Exhibit prospectus for this program and W-9 if credit
                                    card is not accepted.</small> </p>
                        </div>
                    </div>
                    <!-- Form Notes -->
                    <div class="form-notes mb-5">
                        <div class="form-notes-content">
                            <!-- Note 2 -->
                            <div class="form-notes-highlight">
                                <p>NOTE: Funds are not guaranteed for programs without supporting documentation, and all
                                    required approvals.</p>
                                <p>Please upload a W-9 (if it is not a credit card payment) and supporting documentation
                                    before submitting.</p>
                            </div>
                            <p><strong>Please note the following signing authorities</strong></p>
                            <div class="form-grid">
                                <div class="grid-col">
                                    <p> District Manager </p>
                                    <p> $0 - $1500 </p>
                                </div>
                                <div class="grid-col">
                                    <p> Regional Manager </p>
                                    <p> $1501 - $3000 </p>
                                </div>
                                <div class="grid-col">
                                    <p> National Sales Director </p>
                                    <p> $3001 - $5000 </p>
                                </div>
                                <div class="grid-col">
                                    <p> Business Unit Head </p>
                                    <p> $5000+ </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div id="errors" class="invalid"></div> -->
                    <div class="form-footer">
                        <div class="form-alert" id="form-alert"></div>
                        <button type="submit" id="submit" :disabled="submitBtnDisable">Update</button>
                        <button type="button" id="cancel" @click="cancelButton()">Cancel</button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';
import loadingImage from '@/assets/load.gif'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import mixin from '@/mixins/common.utils'

export default {
    mixins: [mixin],
    components: { DatePicker },
    data() {
        return {
            isFirstLoad: true,
            msg: 'Loading ... ',
            url: loadingImage,
            businessUnits: [],
            districts: [],
            brands: [],
            territories: [],
            loading: true,
            submitBtnDisable:false,
            form: {
                businessUnitId: '',
                DistrictId: '',
                TerritoryNumber: '',
                BrandNameId: '',
                DateOfProgram: '',
                RequestingOrganization: '',
                titleOfProgram: '',
                programLocation: '',
                speakerCenterName: '',
                speakerCenterAddress: '',
                addressToSendCheck: '',
                dollarAmountRequested: '',
                TypeOfCost:"" ,
                CreditCardAccepted: '',
                W9Attached: '',
                RequestLetterAttached: '',
                Attachment: [],
                Program_Guid : '',
            },
            form1: {
                businessUnitId: '',
                DistrictId: '',
                TerritoryNumber: '',
                DateOfProgram: '',
                RequestingOrganization: '',
                titleOfProgram: '',
                programLocation: '',
                speakerCenterName: '',
                speakerCenterAddress: '',
                addressToSendCheck: '',
                dollarAmountRequested: '',
                TypeOfCost: '',
                CreditCardAccepted: '',
                W9Attached: '',
                RequestLetterAttached: '',
                Attachment: [],
                Program_Guid : '',
            }
        }
    },
    mounted() {
        this.getBusiness();
        this.getBrand();
        this.getProgramDetails();
        const isFirstLoad = sessionStorage.getItem('isFirstLoad');
        let firstTime = localStorage.getItem('firstTime')
        if (isFirstLoad) {
            console.log("inside here")
        // If the flag exists, it's not the first load, so set isFirstLoad to false
            this.isFirstLoad = false;
        } else {
        // If the flag doesn't exist, it's the first load, so perform the action
        // Perform the action here
        if(firstTime !== 'undefined' && firstTime === "true" ){
            localStorage.setItem("firstTime", "false");
            sessionStorage.setItem('isFirstLoad', false);
        }
        else{
            this.performActionOnFirstLoad();
            // Set the flag in sessionStorage to indicate that the action has been performed
            sessionStorage.setItem('isFirstLoad', true);
        }
        }
    },
    computed: {
        w9AttachedDisableYes() {
            if (this.form.CreditCardAccepted == 'yes') {
                if(this.program_details.Is_CreditCard_Payment != 'yes')
                {
                 this.form.W9Attached = '';
                }
               return true;
            } else{
                return false;
            }
        },
        w9AttachedDisableNo() {
            if (this.form.CreditCardAccepted == 'no') {
              if(this.program_details.Is_CreditCard_Payment != 'no')
                {
                 this.form.W9Attached = '';
                }
                return true;
            } else {
                return false;
            }
        }
       
    },
    methods: {
        performActionOnFirstLoad() {
         this.$store.dispatch('LogOut');
        },
        // disabledDates(date) {

        //     let current_date = new Date();
        //     if (current_date > date) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
        cancelButton() {
            this.$confirm("Are you sure want to Cancel ?")
                .then((resp) => {
                    console.log(resp)
                    if (resp) {
                        window.location.reload();
                    }
                });
        },
        Logout() {
            this.$store.dispatch('LogOut');
        },
        uploadFile() {
            const files = this.$refs.file.files;
            Array.from(files).forEach(element => {
                const file = element;
                if (file.size > 1024 * 1024 * 250) {
                    alert('File too big (>250MB)');
                    return;
                } else {
                    this.form.Attachment.push(element);
                }
            });
        },
        ModifiedDistrictId() {
            if (this.form.DistrictId) {
                this.msg = 'Retrieving Territory Please wait ...'
                this.loading = true;

                axios.get('/territory', {
                    params: {
                        districtId: this.form.DistrictId
                    }
                })
                    .then(resp => {
                        console.log("resp ModifiedDistrictId");
                        this.territories = resp.data.data;
                        this.loading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                    })
            }else{
                this.territories = [];
            }

        },
        submitForm() {
            this.loading = true;
            this.msg = 'Processing ... '
            const formData = new FormData();
            // formData.append('Attachment', this.form.Attachment);
            for (const i of Object.keys(this.form.Attachment)) {
                if(JSON.stringify(this.form.Attachment[i]) != '{}'){
                    formData.append('Attachment', JSON.stringify(this.form.Attachment[i]))
                }
                else{
                    formData.append('Attachment', this.form.Attachment[i])
                }
            }
            formData.append('businessUnitId', this.form.businessUnitId);
            formData.append('DistrictId', this.form.DistrictId);
            formData.append('BrandNameId', this.form.BrandNameId);
            formData.append('TerritoryNumber', this.form.TerritoryNumber);
            formData.append('DateOfProgram', this.form.DateOfProgram);
            formData.append('RequestingOrganization', this.form.RequestingOrganization);
            formData.append('titleOfProgram', this.form.titleOfProgram);
            formData.append('programLocation', this.form.programLocation);
            formData.append('speakerCenterName', this.form.speakerCenterName);
            formData.append('speakerCenterAddress', this.form.speakerCenterAddress);
            formData.append('addressToSendCheck', this.form.addressToSendCheck);
            formData.append('dollarAmountRequested', this.form.dollarAmountRequested);
            formData.append('TypeOfCost', (this.form.TypeOfCost) ? 'Exhibit/Display Fee (GL Code 773007)': false);
            formData.append('CreditCardAccepted', this.form.CreditCardAccepted);
            formData.append('W9Attached', this.form.W9Attached);
            formData.append('RequestLetterAttached', this.form.RequestLetterAttached);
            formData.append('Program_Guid', this.form.Program_Guid);
            
            axios.post('/exhibit-fund/update',
                formData)
                .then((res) => {
                    if (res.data.success) {
                        this.loading = false;
                        this.$fire({
                            title: "Successfully Updated !",
                            type: "success"
                        }).then(r => {
                            if (r.value) {
                                this.Logout();
                                // location.reload();
                            }
                        });
                    }


                    //this.$router.push('/exhibit-fund-app/create');

                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                    this.$fire({
                        title: "Error",
                        type: "error",

                    }).then(r => {
                        this.loading = false;
                      //  window.location.reload();

                    });

                });
        },
        removeFile(i) {
            console.log("here----")
            const data = Array.from(this.form.Attachment)
            document.getElementById('attachments').value = "";
            console.log(data);
            data.splice(i, 1)
            console.log(data)
            this.form.Attachment = data;
        },
        DateOfProgramChanged() {

            let selectedDate = this.form.DateOfProgram;
            return true;
            if (selectedDate) {
               this.submitBtnDisable = false;
            }else{
                this.submitBtnDisable = true;
            }
        },
        getDistrict() {

            if (this.form.businessUnitId) {
                this.loading = true;
                this.msg = 'Retrieving District Please wait ...';

                axios.get('/districts', {
                    params: {
                        business_unit_id: this.form.businessUnitId
                    }
                })
                    .then((resp) => {
                        console.log(resp.data.data);
                        this.districts = resp.data.data;
                        this.loading = false;
                    })
            }else{
                this.districts = [];
            }

        },
        getBusiness() {
            let businessUnitId = this.form.businessUnitId;
            this.loading = true;
            axios.get('/business-units')
                .then((resp) => {
                    console.log(resp.data.data);
                    this.businessUnits = resp.data.data;
                    this.loading = false;
                })

        },
        getBrand() {
            axios.get('/brand')
                .then((resp) => {
                    console.log(resp.data.data);
                    this.brands = resp.data.data;
                })

        },
        async getProgramDetails(){
          this.loading = true;
          let program_id= this.$route.query.program_id;
          
          axios.get('/exhibit-fund/'+program_id+'/details')
            .then(resp => {
              console.log("Resp",resp);
              if(resp.data.data){
              this.loading = true;
              this.program_details = resp.data.data;
              this.program_details.Attachments = JSON.parse(resp.data.data.Attachments);

                this.form.businessUnitId = this.program_details.BusinessUnitID;
                this.getDistrict();
                this.form.DistrictId = this.program_details.DistrictID;
                this.ModifiedDistrictId();
                this.form.BrandNameId = this.program_details.Brand_id;
                this.form.TerritoryNumber = this.program_details.TerritoryID;
                this.form.DateOfProgram = this.program_details.Date_Of_Program.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");
                this.form.RequestingOrganization = this.program_details.Requesting_Organization;
                this.form.titleOfProgram = this.program_details.Title_Of_Program;
                this.form.programLocation = this.program_details.Program_Location;
                this.form.speakerCenterName = this.program_details.Speaker_Center_Name;
                this.form.speakerCenterAddress = this.program_details.Speaker_Center_Address;
                this.form.addressToSendCheck = this.program_details.Address_to_send_Check;
                this.form.dollarAmountRequested = this.program_details.Dollar_amt;
                this.form.TypeOfCost = this.program_details.Type_Of_Cost ? true: false;
                this.form.CreditCardAccepted = this.program_details.Is_CreditCard_Payment;
                this.form.W9Attached = this.program_details.Is_W9_Attached;
                this.form.Program_Guid = program_id;
                this.form.RequestLetterAttached = this.program_details.Request_Letter_Attached;
                this.form.Attachment = this.program_details.Attachments

                for (const i of Object.keys(this.form.Attachment)) {
                    this.form.Attachment[i].name = this.form.Attachment[i].originalname
                }

              this.loading = false;
              }else {
                this.loading = false;
                  this.$fire({
                    title: "This Exhibit Fund Has been deleted!",
                    type: "info",
                  }).then(r => {
                    this.$router.push('/');
                  });
              }
            })
        },        
    }
}
</script>
<style>
#submit:disabled {
    background-color: #e3e3e3;
    cursor: not-allowed;
    pointer-events: all !important;
}
</style>